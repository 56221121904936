<template>
  <div class="detailWrapper">
    <router-link to="/" class="backLink">
      <img src="../assets/svg/arrow-left.svg" class="arrowLeft" />
      Zur Übersicht
    </router-link>
    <div class="titleWrapper">
      <img
        v-if="action.image"
        class="image"
        :src="require(`@/assets/${action.image}`)"
      />
      <div class="detailActionName mb-0">{{ action.name }}</div>
    </div>
    <button
      class="descriptionWrapper my-3 w-100"
      @click="showFullDescription = !showFullDescription"
    >
      <div
        :class="{ description: true, open: showFullDescription }"
        ref="description"
      >
        {{ action.description }}
      </div>
      <div v-if="showToggleDescription" class="py-1 toggleWrapper">
        {{ showFullDescription ? "Weniger zeigen" : "Mehr zeigen" }}
        <img
          class="image toggleArrow"
          :src="require(`../assets/svg/${descriptionArrow}.svg`)"
        />
      </div>
    </button>

    <SuccessView
      v-if="action.progress === action.maxProgress"
      class="successView"
    ></SuccessView>
    <QRCodeScanner
      v-else-if="Object.keys(action).length > 0"
      @update="setAction"
    ></QRCodeScanner>
    <ProgressBar
      class="progressBar"
      :height="30"
      :value="action.progress"
      :min="action.minProgress"
      :max="action.maxProgress"
      :showText="true"
    ></ProgressBar>
  </div>
</template>

<script>
import QRCodeScanner from "./QRCodeScanner";
import ProgressBar from "./ProgressBar";
import SuccessView from "./SuccessView";

const DESCRIPTION_HEIGHT_CLOSED = 40;

export default {
  data() {
    return {
      showFullDescription: false,
      showToggleDescription: false,
      action: {},
    };
  },
  watch: {
    action(val) {
      if (val.description) {
        setTimeout(() => {
          const elmHeight = this.$refs.description.scrollHeight;

          if (elmHeight > DESCRIPTION_HEIGHT_CLOSED) {
            this.showToggleDescription = true;
          }
        }, 0);
      }
    },
  },
  computed: {
    descriptionArrow() {
      return this.showFullDescription ? "chevron-up" : "chevron-down";
    },
  },
  created() {
    fetch("/api/user/1/subscription/event/12345")
      .then((response) => response.json())
      .then((data) => {
        this.setAction(data);
      });
  },
  components: {
    QRCodeScanner,
    ProgressBar,
    SuccessView,
  },
  methods: {
    setAction({ event, subscription }) {
      this.action = {
        id: event.eventId,
        name: event.name,
        description: event.description,
        image: event.imagePath,
        progress: subscription.progress,
        minProgress: subscription.minProgress,
        maxProgress: subscription.maxProgress,
      };
    },
  },
};
</script>

<style scoped>
.detailWrapper {
  padding: 16px 18px 24px;
}

.titleWrapper {
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  gap: 6px;
}
.image {
  width: 40px;
  height: 40px;
  object-fit: contain;
}
.toggleArrow {
  width: 20px;
  height: 20px;
}
.detailActionName {
  font-size: 24px;
  margin-bottom: 16px;
  text-align: left;
}

.arrowLeft {
  width: 20px;
  height: 20px;
}
.backLink {
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 24px;
  color: inherit;
}

.descriptionWrapper {
  font-size: 14px;
  color: #8e8e8f;
  background-color: transparent;
  text-align: left;
  padding: 0;
  border: 0;
  line-height: 20px;
}
.description {
  max-height: 40px;
  overflow: hidden;

  transition: max-height 0.3s ease-out;
}
.description.open {
  max-height: 80px;
}
.toggleWrapper {
  text-align: center;
  font-size: 12px;
  opacity: 0.6;
}
.progressBar {
  margin-top: 35px;
}
.successView {
  margin: 24px 18px 36px;
}
</style>
