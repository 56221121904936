<template>
  <div class="overviewWrapper">
    <div
      v-for="(action, key) in actions"
      :key="'actionList_' + key"
      class="actionListWrapper"
    >
      <h1 class="actionTitle">{{ action.title }}</h1>
      <ul class="actionsList p-0 m-0">
        <li v-for="activeSub in action.actions" :key="activeSub.id">
          <router-link :to="'/action/' + activeSub.id" class="actionItem">
            <img
              class="actionImage"
              :src="require(`@/assets/${activeSub.image}`)"
            />
            <div
              :class="{
                isFinished: activeSub.progress === activeSub.maxProgress,
              }"
            >
              <div
                :class="{
                  name: true,
                  'mb-1':
                    activeSub.progress > 0 &&
                    activeSub.progress < activeSub.maxProgress,
                }"
              >
                {{ activeSub.name }}
              </div>
              <ProgressBar
                v-if="
                  'progress' in activeSub &&
                  activeSub.progress > 0 &&
                  activeSub.progress < activeSub.maxProgress
                "
                :value="activeSub.progress"
                :min="activeSub.minProgress"
                :max="activeSub.maxProgress"
              ></ProgressBar>
              <div
                v-else-if="
                  'progress' in activeSub &&
                  activeSub.progress === activeSub.maxProgress
                "
              >
                <img src="@/assets/svg/check.svg" />
              </div>
            </div>
            <ImageButton image="svg/chevron-right.svg" :size="20"></ImageButton>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import ImageButton from "./ImageButton";
import ProgressBar from "./ProgressBar";

export default {
  data() {
    return {
      myActions: [],
      suggestedActions: [],
    };
  },
  components: {
    ImageButton,
    ProgressBar,
  },
  computed: {
    actions() {
      return [
        ...(this.myActions.length > 0
          ? [
              {
                title: "Deine Aktionen",
                actions: [...this.myActions],
              },
            ]
          : []),
        ...(this.suggestedActions.length > 0
          ? [
              {
                title: "Könnte dir auch gefallen",
                actions: [...this.suggestedActions],
              },
            ]
          : []),
      ];
    },
  },
  created() {
    fetch("/api/user/1/subscription")
      .then((response) => response.json())
      .then((data) => {
        this.myActions = data.map(({ event, subscription }) => ({
          id: event.eventId,
          name: event.name,
          image: event.imagePath,
          progress: subscription.progress,
          minProgress: subscription.minProgress,
          maxProgress: subscription.maxProgress,
        }));
      });
    fetch("/api/event")
      .then((response) => response.json())
      .then((data) => {
        this.suggestedActions = data.map((event) => ({
          id: event.eventId,
          name: event.name,
          image: event.imagePath,
        }));
      });
  },
};
</script>

<style scoped>
.actionListWrapper + .actionListWrapper {
  margin-top: 36px;
}

.overviewWrapper {
  padding: 24px 18px;
}

.actionsList {
  list-style: none;
}
.actionTitle {
  font-size: 24px;
  margin-bottom: 16px;
  text-align: left;
  font-weight: 400;
}
.actionItem {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  gap: 6px;
  padding: 12px;
  border-radius: 6px;
  border: 0;
  color: inherit;

  background-color: #fbfbfb;
  box-shadow: 0 1px 4px rgba(114, 114, 114, 0.5);
}
li + li {
  margin-top: 24px;
}
.actionImage {
  width: 40px;
  height: 40px;
  object-fit: contain;
}
.name {
  text-align: left;
  font-size: 16px;
}

.isFinished {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
