var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"overviewWrapper"},_vm._l((_vm.actions),function(action,key){return _c('div',{key:'actionList_' + key,staticClass:"actionListWrapper"},[_c('h1',{staticClass:"actionTitle"},[_vm._v(_vm._s(action.title))]),_c('ul',{staticClass:"actionsList p-0 m-0"},_vm._l((action.actions),function(activeSub){return _c('li',{key:activeSub.id},[_c('router-link',{staticClass:"actionItem",attrs:{"to":'/action/' + activeSub.id}},[_c('img',{staticClass:"actionImage",attrs:{"src":require(`@/assets/${activeSub.image}`)}}),_c('div',{class:{
              isFinished: activeSub.progress === activeSub.maxProgress,
            }},[_c('div',{class:{
                name: true,
                'mb-1':
                  activeSub.progress > 0 &&
                  activeSub.progress < activeSub.maxProgress,
              }},[_vm._v(" "+_vm._s(activeSub.name)+" ")]),(
                'progress' in activeSub &&
                activeSub.progress > 0 &&
                activeSub.progress < activeSub.maxProgress
              )?_c('ProgressBar',{attrs:{"value":activeSub.progress,"min":activeSub.minProgress,"max":activeSub.maxProgress}}):(
                'progress' in activeSub &&
                activeSub.progress === activeSub.maxProgress
              )?_c('div',[_c('img',{attrs:{"src":require("@/assets/svg/check.svg")}})]):_vm._e()],1),_c('ImageButton',{attrs:{"image":"svg/chevron-right.svg","size":20}})],1)],1)}),0)])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }