<template>
  <div>
    <div v-if="showText" class="progressAmount">
      {{ percentageValue }}% erreicht
    </div>
    <b-progress
      :value="value"
      :max="max"
      :min="min"
      variant="success"
      :height="height + 'px'"
    ></b-progress>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: 0,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 100,
    },
    height: {
      type: Number,
      default: 10,
    },
    showText: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    percentageValue() {
      const val = (this.value / (this.max - this.min)) * 100;
      return val.toFixed(2);
    },
  },
};
</script>

<style scoped>
.progressAmount {
  color: #8e8e8f;
  font-size: 18px;
  text-align: left;
}
</style>
