<template>
  <button class="imageButton" @click="$emit('click')">
    <img
      :alt="altText"
      :src="require(`@/assets/${image}`)"
      class="img"
      :style="{ '--size': size + 'px' }"
    />
  </button>
</template>

<script>
export default {
  props: {
    image: {
      type: String,
      default: "",
    },
    altText: {
      type: String,
      default: "",
    },
    size: {
      type: Number,
      default: 40,
    },
  },
};
</script>

<style scoped>
.imageButton {
  background-color: transparent;
  border: none;
}

.img {
  width: var(--size);
  height: var(--size);
  object-fit: contain;
}
</style>
