<template>
  <div>
    <div class="loading" :style="{ '--size': loadingSize + 'px' }"></div>
    <div v-if="loadingText" class="loadingText">
      {{ loadingText }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    loadingText: {
      type: String,
      default: "",
    },
    loadingSize: {
      type: Number,
      default: 40,
    },
  },
};
</script>

<style scoped>
.loading {
  --size: 40px;

  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  left: auto;
  top: auto;
  position: relative;
  z-index: 1;
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-top-color: #fff;
}

.loadingText {
  margin-left: 8px;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
</style>
