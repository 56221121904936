<template>
  <div>
    <div class="mb-1 info">Scanne bitte den QR Code</div>
    <div class="position-relative">
      <div v-if="!qrScannerLoaded" class="qrScannerPlaceholder">
        <img src="../assets/svg/qr_placeholder.svg" class="placeholder" />
      </div>
      <div id="qr-scanner" width="500px" ref="qrScanner"></div>
      <div v-if="scanInProgress" class="scanInProgress">
        <LoadingAnimation
          class="loadingWheel"
          loadingText="QR Code wird verarbeitet"
        ></LoadingAnimation>
      </div>
    </div>
  </div>
</template>

<script>
import { Html5Qrcode } from "html5-qrcode";
import LoadingAnimation from "./LoadingAnimation";

export default {
  data() {
    return {
      scanInProgress: false,
      qrScannerLoaded: false,
      html5QrCode: null,
    };
  },
  components: {
    LoadingAnimation,
  },
  mounted() {
    this.html5QrCode = new Html5Qrcode("qr-scanner");
    const config = {
      fps: 10,
      qrbox: 200,
    };
    var _this = this;
    this.html5QrCode
      .start({ facingMode: "environment" }, config, this.onScanSuccess)
      .then(() => {
        setTimeout(function () {
          _this.html5QrCode.applyVideoConstraints({
            focusMode: "continuous",
          });
        }, 2000);
        this.qrScannerLoaded = true;
      });
  },
  beforeDestroy() {
    this.html5QrCode.stop();
  },
  methods: {
    onScanSuccess(decodedText) {
      if (!this.scanInProgress) {
        this.scanInProgress = true;

        const billParts = decodedText.split("_");
        const [algorithm, signatureProvider] = billParts[1].split("-");

        const postData = {
          id: decodedText,
          algorithm,
          signatureProvider,
          cashRegisterNumber: billParts[2],
          billNumber: billParts[3],
          amountInEuros: +billParts[5].replace(",", "."),
          taxAmount: +billParts[6].replace(",", "."),
        };

        fetch("/api/user/1/subscription/event/12345/bill", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(postData),
        })
          .then((response) => response.json())
          .then((data) => {
            this.$emit("update", data);
            this.scanInProgress = false;
          });
      }
    },
    onScanFailure(error) {
      if (
        error !==
        "QR code parse error, error = NotFoundException: No MultiFormat Readers were able to detect the code."
      ) {
        console.warn(`Code scan error = ${error}`);
      }
    },
  },
};
</script>

<style scoped>
.info {
  text-align: left;
}
.scanInProgress {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(4px);
  pointer-events: none;

  display: flex;
  align-items: center;
  justify-content: center;

  color: #fff;
}
.loadingWheel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.qrScannerPlaceholder {
  display: flex;
}
.qrScannerPlaceholder .placeholder {
  width: 250px;
  height: 250px;
  margin: auto;
  opacity: 0;
}
</style>
