<template>
  <div class="navbar">
    <div class="mainInfo">
      <img
        alt="Servus, Region Logo"
        title="Servus, Region Logo"
        src="../assets/cart.png"
        class="logo"
      />
      <div class="title">{{ title }}</div>
    </div>
    <ImageButton image="svg/menu.svg" altText="Open Menu"></ImageButton>
  </div>
</template>

<script>
import ImageButton from "./ImageButton";

export default {
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  components: {
    ImageButton,
  },
  methods: {
    openCloseMenu() {
      // open & close menu
    },
  },
};
</script>

<style scoped lang="css">
.navbar {
  position: sticky;
  top: 0;
  height: 60px;
  box-shadow: 0 2px 4px rgba(84, 84, 84, 0.5);
  background: #fff;
}
.mainInfo {
  display: flex;
  align-items: center;
  gap: 20px;
}

.logo {
  width: 40px;
  height: 40px;
}
.title {
  font-size: 22px;
}

.menuButton {
  background-color: transparent;
  border: none;
}
</style>
